@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@layer components {
    .topBar {
        @apply flex justify-center bg-[#0e47a1] w-full border-b-[2px] border-b-[#0e47a1] items-center h-[50px]  fixed top-0 left-0 z-10;
    }
    .mobi_calc_1 {
        @apply flex flex-col items-center justify-center pt-[120px] mx-6 pb-[90px];
    }
    .mobi_calc_2 {
        @apply text-[12px]  px-8 flex-col flex items-center font-semibold fixed top-[120px] bg-white;
    }
    .base_text {
        @apply text-[12px] text-black/60;
    }
    .footer {
        @apply w-full bg-[#0e47a1] py-4 px-4 font-normal flex fixed bottom-0 left-0;
    }
    .text_white {
        @apply text-white text-[11px];
    }
    .h2_pb2 {
        @apply  text-[11px];
    }
    .title_text {
        @apply text-[20px] font-bold;
    }
    .sub_title {
        @apply text-[12px] font-bold
    }
    .li_styles {
        @apply relative pl-[12px];
    }
    .bg_color {
        @apply bg-[#0e47a1];
    }
    .text_color {
        @apply text-[#0e47a1];
    }
    .main {
        @apply flex flex-col pt-[20px] px-8 items-center  pb-4 w-full;
    }
    .web_step1 {
        @apply w-[400px]  mt-16 ;
    }
    .progressBorder {
        @apply w-[16px] h-[16px] rounded-full bg-[#f6fbf7] border-[2px]
    }
    .before_line {
        @apply before:content-[''] before:absolute before:bg-[#eee] before:w-full before:h-[2px] before:z-[-1];
    }
    .button_off {
        @apply bg-gray-300 w-[124px] py-2 rounded-lg text-white font-normal;
    }
    .button_on {
        @apply bg-[#0e47a1] w-[124px] py-2 rounded-lg text-white font-normal;
    }
    .input {
        @apply w-[230px] text-[12px] py-2 px-4 border focus:outline-none hover:outline-none hover:border-[#0e47a1] rounded-lg;
    }
    .checkOff {
        @apply border w-[18px] h-[18px] rounded-full flex justify-center items-center;
    }
    .checkOn {
        @apply border w-[18px] h-[18px] rounded-full flex justify-center items-center bg-[#0e47a1];
    }
    .bold_12 {
        @apply text-[12px] font-bold;
    }
}

